import styled from "styled-components";

export const VidaContainer = styled.section`
  overflow: hidden;
  span {
    :first-child {
      width: 1px;
      height: 1px;
      background: red;
      position: absolute;
      top: 125px;
      z-index: 5;
    }
  }
  @media (max-width: 991px) {
    span {
      :first-child {
        width: 0px;
        height: 0px;
        position: absolute;
        top: 75px;
        z-index: 5;
      }
    }
  }
`;
