import styled, { css } from 'styled-components';

import { styles } from 'const/styles';

const defaultFontInput = () => css`
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
`;

export const ErrorMessage = styled.span`
  color: red;
  ${defaultFontInput()}
`;

const InputCustomContainer = styled.div`
  border-bottom: 2px solid ${styles?.color?.primaryColor};
  ${defaultFontInput()}
  height: 29px;
  color: #51575c;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  position: relative;

  .required {
    color: red;
  }

  input,
  span,
  label {
    ${defaultFontInput()}
  }

  input {
    width: 100%;
    border: none;
    color: #51575c;
    background: none;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    ::placeholder {
      color: transparent;
    }
    :focus,
    :not(:placeholder-shown) {
      outline: none;
      & + label {
        transform: translateY(-12px) scale(0.7);
        transform-origin: left top;
      }
    }
  }

  label {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 5px;
    transition: transform 0.5s, color 0.3s;
  }
`;

export default InputCustomContainer;
