import { ArrowElement, ContainerArrow } from "./styles/arrows.style";

const Arrows = (props) => {
  const {
    actionLeft,
    actionRight,
    activeLeft = true,
    activeRight = true,
    bg = "",
  } = props;
  return (
    <ContainerArrow>
      <ArrowElement
        directionT={false}
        active={activeLeft}
        bg={bg}
        onClick={() => actionLeft()}
      >
        <div />
      </ArrowElement>
      <ArrowElement
        directionT={true}
        active={activeRight}
        bg={bg}
        onClick={() => actionRight()}
      >
        <div />
      </ArrowElement>
    </ContainerArrow>
  );
};

export default Arrows;
