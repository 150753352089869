import styled from "styled-components";

export const SectionContainer = styled.section`
  span {
    :first-child {
      display: block;
      width: 100vw;
      height: 100px;
      z-index: 5;
    }
  }
  overflow: hidden;

  @media (max-width: 991px) {
    span {
      :first-child {
        height: 50px;
      }
    }
  }
`;
