import { HeaderFacilitaContainer } from "./styles/headerFacilitarVida.styles";
import { Container } from "../";
const HeaderFacilitarVida = () => {
  return (
    <HeaderFacilitaContainer>
      <div>
        <Container>
          <h5>Tudo o que a Momento faz tem um único objetivo: </h5>
          <h2>facilitar a vida da sua empresa</h2>
        </Container>
      </div>
    </HeaderFacilitaContainer>
  );
};

export default HeaderFacilitarVida;
