import { useContext } from "react";
import { MenuContext } from "context";
import { InView } from "react-intersection-observer";

import { ContactForm } from "components";

const Contact = () => {
  const visible = useContext(MenuContext);
  return (
    <InView
      as="section"
      trackVisibility
      delay={100}
      style={{zIndex:"5", background: "white", position: "relative"}}
      threshold={0.2}
      onChange={(e) => {
        if (e) {
          visible?.setMenuVisible("cotação");
        }
      }}
    >
      <span id={"cotação"} />
      <ContactForm />
    </InView>
  );
};
export default Contact;
