import { useState, useEffect } from "react";
import {
  SliderMotivosContainer,
  PrincipalBlock,
  Block,
  ContainerArrow,
} from "./styles/sliderMotivos.styles";
import { ArrowSlider } from "components";

const SliderMotivos = ({ data }) => {
  const [element, setElement] = useState(1);
  const [activeLeft, setActiveLeft] = useState(false);
  const [activeRight, setActiveRight] = useState(false);

  const left = () => {
    setElement((prevState) => {
      if (prevState - 1 < 1) {
        setActiveLeft(false);
        return 1;
      } else {
        setActiveLeft(true);
        return prevState - 1;
      }
    });
  };
  const right = () => {
    setElement((prevState) => {
      if (prevState + 1 > data?.length) {
        setActiveRight(false);
        return data?.length;
      } else {
        setActiveRight(true);
        return prevState + 1;
      }
    });
  };

  useEffect(() => {
    if (data?.length > element) {
      setActiveRight(true);
    } else {
      setActiveRight(false);
    }
    if (element > 1) {
      setActiveLeft(true);
    } else {
      setActiveLeft(false);
    }
  }, [element, data?.length]);

  return (
    <SliderMotivosContainer>
      <ContainerArrow>
        <ArrowSlider
          activeLeft={activeLeft}
          activeRight={activeRight}
          actionLeft={left}
          actionRight={right}
          bg={"#773594"}
        />
      </ContainerArrow>

      {data?.map((listItems, index) => {
        return (
          <PrincipalBlock
            key={index + "box"}
            active={element === index + 1 ? true : false}
          >
            {listItems?.map((item, itemIndex) => {
              if (item?.options?.length > 0) {
                return (
                  <Block
                    key={item?.id}
                    options={true}
                    idData={item?.id}
                    active={element === index + 1 ? true : false}
                    figure={item?.figure}
                    translate={item?.translateData ? 1 : 0}
                    align_self={item?.align_self}
                  >
                    {item?.options?.map((options, indexOptions) => {
                      return (
                        <Block
                          key={options?.id}
                          idData={options?.id}
                          active={element === index + 1 ? true : false}
                          figure={options?.figure}
                          align_self={options?.align_self}
                          bg={options?.color}
                          icoSize={options?.icoSize}
                          textSize={options?.textSize}
                          textSizeMobile={options?.textSizeMobile}
                          translate={options?.translateData ? 1 : 0}
                          translateX={options?.translateDataX}
                          translateY={options?.translateDataY}
                        >
                          <img src={options?.ico} alt="figure" />
                          <p>
                            {options?.text}
                            {options?.text1 ? (
                              <>
                                <br />
                                {options?.text1}
                              </>
                            ) : (
                              ""
                            )}
                          </p>
                        </Block>
                      );
                    })}
                  </Block>
                );
              }
              return (
                <Block
                  key={item?.id}
                  idData={item?.id}
                  bg={item?.color}
                  icoSize={item?.icoSize}
                  align_self={item?.align_self}
                  figure={item?.figure}
                  textSize={item?.textSize}
                  textSizeMobile={item?.textSizeMobile}
                  active={element === index + 1 ? true : false}
                  translate={item?.translateData ? 1 : 0}
                  translateX={item?.translateDataX}
                  translateY={item?.translateDataY}
                >
                  <img src={item?.ico} alt="figure" />
                  <p>
                    {item?.text}
                    {item?.text1 ? (
                      <>
                        <br />
                        {item?.text1}
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </Block>
              );
            })}
          </PrincipalBlock>
        );
      })}
    </SliderMotivosContainer>
  );
};

export default SliderMotivos;
