import styled from "styled-components";
import { styles } from "const/styles";

export const FooterBase = styled.footer`
  /* background-color: ${styles.color?.primaryColor}; */
  background: red;
  z-index: 5;
  background: white;
  position: relative;
`;
