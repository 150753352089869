import styled from "styled-components";
import { styles } from "const/styles";

export const ContainerMenu = styled.div`
  /* background: red; */
  margin-left: 40px;
  width: 825px;
  box-sizing: border-box;
  @media (max-width: 991px) {
    right: 20px;
    top: 20px;
    width: 62px;
    height: 62px;
    position: absolute;
    display: block;
  }
`;
export const ButtonMobile = styled.button`
  display: none;
  @media (max-width: 991px) {
    background-color: white;
    transform: rotate(45deg);
    border: none;
    width: 44px;
    height: 44px;
    margin: 0 auto;
    margin-top: 10px;
    display: block;
    box-shadow: 0px 0px 3px 0px #bbb;
  }
  svg {
    font-size: 20px;
    color: ${styles?.color?.primaryColor};
    transform: rotate(-45deg);
  }
`;

export const ContainerOptions = styled.ul`
  display: flex;
  list-style: none;
  margin: 9px;
  li {
    margin-right: 46px;
    box-sizing: border-box;
    position: relative;
    span {
      opacity: 0;
      display: block;
      position: absolute;
      left: calc(50% - 10px);
      width: 20px;
      color: ${(props) =>
        props?.activeHeader
          ? styles?.color?.primaryColor
          : styles?.color?.secondaryColor};
      text-align: center;
    }
    &.visible {
      a {
        transition: all 0.5s;
      }
      span {
        transition: opacity 0.5s;
        opacity: 1;
      }
    }
    :last-child {
      margin-right: 0;
    }
  }
  a {
    text-decoration: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: ${(props) =>
      props?.activeHeader ? styles?.color?.primaryColor : "#ffffff"};
    :hover {
      transition: all 0.5s;
      ~ span {
        transition: all 0.5s;
        opacity: 1;
        display: block;
        position: absolute;
        left: calc(50% - 10px);
        width: 20px;
        text-align: center;
        color: ${(props) =>
          props?.activeHeader
            ? styles?.color?.primaryColor
            : styles?.color?.secondaryColor};
        svg {
          transition: all 0.5s;
        }
      }
    }
  }

  @media (min-width: 1430px) {
    position: absolute;
    right: ${(props) => (props?.activeHeader ? "auto" : "18vw")};
    left: ${(props) => (props?.activeHeader ? "26vw" : "auto")};
    top: ${(props) => (props?.activeHeader ? "18px" : "0")};
  }

  @media (max-width: 991px) {
    /* flex-direction: column;*/
    display: none;
  }
`;
