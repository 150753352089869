import styled from "styled-components";
import { styles } from "const/styles";
export const ContainerMenuMobile = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: ${(props) => (props?.animationMenu ? "block" : "none")};
    background: ${styles?.color?.primaryColor};
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 100;
    opacity: 0;
    right: 0vw;
    animation: ${(props) =>
      props?.active
        ? "moveMenuX 0.35s ease-in-out 0s alternate forwards"
        : "moveOutMenuXOut 0.35s ease-in-out 0s alternate forwards"};
    @keyframes moveMenuX {
      0% {
        transform: translateX(100vw);
        opacity: 1;
      }
      100% {
        transform: translateX(0vw);
        opacity: 1;
      }
    }
    @keyframes moveOutMenuXOut {
      0% {
        transform: translateX(0vw);
        opacity: 1;
      }
      100% {
        transform: translateX(100vw);
        opacity: 1;
      }
    }
  }
`;

export const ContainerButton = styled.div`
  right: 20px;
  top: 20px;
  width: 62px;
  height: 62px;
  position: absolute;
  display: block;
  button {
    background-color: white;
    transform: rotate(45deg);
    border: none;
    width: 44px;
    height: 44px;
    margin: 0 auto;
    margin-top: 10px;
    display: block;
    box-shadow: 0px 0px 3px 0px #bbb;

    svg {
      font-size: 20px;
      color: ${styles?.color?.primaryColor};
      transform: rotate(-45deg);
    }
  }
`;

export const ContainerOptions = styled.div`
  position: absolute;
  top: 106px;
  left: 50px;
  list-style: none;
  li {
    margin-bottom: 42px;
  }
  a {
    text-decoration: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: ${(props) =>
      props?.activeHeader ? styles?.color?.primaryColor : "#ffffff"};
  }
`;
