import styled from "styled-components";

export const PlayerSection = styled.section`
  background-color: #51575c;
  margin-bottom: 150px;
  span {
    :first-child {
      height: 73px;
      display: block;
    }
  }
  @media (max-width: 991px) {
    margin-bottom: 120px;
    span {
    :first-child {
      height: 0;
    }
  } 
  }
`;
