import styled from "styled-components";
import { styles } from "const/styles";
import arrow from "assets/img/form/arrowSelect.png"
const SelectCustom = styled.select`
  border: none;
  border-bottom: 2px solid ${styles?.color?.primaryColor};
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  height: 29px;
  color: #51575c;
  background: none;
   background-image: url(${arrow});
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  ::-ms-expand {
    display: none; 
  }
  :focus {
    outline: none;
  }
`;
export default SelectCustom;
