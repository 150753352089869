import {
  BodyFalitaContainer,
  ContainerFigure,
  Box,
  CarImage,
  UserImage
} from "./styles/bodyFacilitarVida.styles";
import { Container } from "../";
import { styles } from "const/styles";
import CarVida from "assets/img/vida/carMomentoVida.png";
import User from "assets/img/vida/happy-client-momento.png";

const BodyFacilitarVida = () => {
  return (
    <BodyFalitaContainer>
      <div>
        <Container>
          <p>
            Nascemos em 2019 para transformar a experiência com locação de
            veículos e frotas. Atendemos clientes de todos os estados do país e
            entregamos frotas em todo o território nacional, para que você não
            tenha nenhum tipo de preocupação logística. <br />
            <br />
            Criamos dispositivos que facilitam e agilizam a vida das pessoas,
            como a retirada automatizada e o agendamento de revisões.
            Trabalhamos para você não perder nem um minuto do seu tempo se
            preocupando com veículos.
            <br />
            <br /> Nossa tarefa é permitir que você tenha foco absoluto no seu
            negócio, ganhando tempo para pensar nas questões importantes. Nosso
            time é valorizado, motivado e treinado para fazer o atendimento mais
            ágil, personalizado e cordial possível.
          </p>
        </Container>
        <ContainerFigure>
          <Box transparent={true} />
          <Box ml={true}>
            <CarImage src={CarVida} alt="car vida momento" />
            <UserImage src={User} alt="user vida momento" />
          </Box>
          <Box color={styles?.color?.secondaryColor}>
            <div />
          </Box>
        </ContainerFigure>
      </div>
    </BodyFalitaContainer>
  );
};

export default BodyFacilitarVida;
