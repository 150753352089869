import {
  Header,
  Motivos,
  FacilitarVida,
  PlayerFixed,
  Contact,
  Footer,
} from "../";
import { useState } from "react";
import { MenuContext } from "context";

const Landing = () => {
  const [menuVisible, setMenuVisible] = useState("");

  return (
    <MenuContext.Provider value={{ menuVisible, setMenuVisible }}>
      <Header />
      <Motivos />
      <FacilitarVida />
      <PlayerFixed />
      <Contact />
      <Footer />
    </MenuContext.Provider>
  );
};

export default Landing;
