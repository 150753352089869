import styled from "styled-components";

export const SliderMotivosContainer = styled.div`
  position: relative;
  @media (max-width: 991px) {
    display: flex;
    overflow-x: auto;
  }
`;

export const PrincipalBlock = styled.div`
  width: calc(100vw - 0px);
  margin: 0 auto;
  background: white;
  display: flex;
  overflow: hidden;
  justify-content: space-evenly;

  :first-child {
    position: absolute;
    top: 88px;
    z-index: ${(props) => (props?.active ? 1 : 2)};
    animation: ${(props) =>
      props?.active
        ? "motAnimationOut 0.5s ease-in-out alternate forwards"
        : "motAnimation 0.5s ease-in-out alternate forwards"};
  }
  :last-child {
    position: absolute;
    top: 88px;
    z-index: ${(props) => (props?.active ? 2 : 1)};
    animation: ${(props) =>
      props?.active
        ? "motAnimation 0.5s ease-in-out alternate forwards"
        : "motAnimationOut 0.5s ease-in-out alternate forwards"};
  }
  @keyframes motAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes motAnimationOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @media (max-width: 991px) {
    width: auto;
    display: flex;
    overflow: initial;
    :last-child, :first-child {
      animation: none;
      display: flex;
      position: relative;
      z-index: 2;
      top: 0;
      transform: translateX(0);
    }
  }
`;

export const ContainerArrow = styled.div`
  width: calc(100% - 80px);
  box-sizing: border-box;
  height: 50px;
  margin: 0 auto;
  /* background: red; */
  margin-bottom: 38px;
  > div {
    justify-content: flex-end;
    display: flex;
    position: absolute;
    left: 68vw;
  }

  @media (max-width: 991px) {
    > div {
      display: none;
    }
  }
`;

export const Block = styled.div`
  margin-right: 3px;
  box-sizing: border-box;
  display: ${(props) => (props?.options ? "flex" : "block")};
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: space-between;
  align-self: ${(props) => (props?.align_self ? props?.align_self : "auto")};
  background-color: ${(props) => (props?.bg ? props?.bg : "white")};
  width: ${(props) => (props?.figure ? props?.figure?.width : "32vw")};
  height: ${(props) => (props?.figure ? props?.figure?.height : "32vw")};
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  animation: ${(props) =>
    props?.active
      ? `moveBlock${
          props.idData ? props.idData : ""
        } 1.45s ease-in-out 0s alternate forwards`
      : `moveBlockOut${
          props.idData ? props.idData : ""
        } 1.45s ease-in-out 0s alternate forwards`};
  @keyframes moveBlock${(props) => (props.idData ? props.idData : "")} {
    0% {
      transform: ${(props) =>
        props?.translate
          ? `translate(` + props?.translateX + "vw," + props?.translateY + "vw)"
          : "translate(0px, 0px)"};
    }
    100% {
      transform: translate(0px, 0px);
    }
  }
  @keyframes moveBlockOut${(props) => (props.idData ? props.idData : "")} {
    0% {
      transform: translate(0px, 0px);
    }
    100% {
      transform: ${(props) =>
        props?.translate
          ? `translate(` + props?.translateX + "vw," + props?.translateY + "vw)"
          : "translate(0px, 0px)"};
    }
  }
  padding-left: ${(props) =>
    props?.options
      ? ""
      : props?.figure?.width === "32vw" && props?.figure?.height === "32vw"
      ? "65px"
      : "45px"};
  padding-top: ${(props) =>
    props?.options
      ? ""
      : props?.figure?.width === "32vw" && props?.figure?.height === "32vw"
      ? "65px"
      : "45px"};
  font-size: ${(props) =>
    (props?.figure?.width === "32vw" && props?.figure?.height === "32vw") ||
    !props?.figure
      ? "45px"
      : "22px"};
  line-height: ${(props) =>
    (props?.figure?.width === "32vw" && props?.figure?.height === "32vw") ||
    !props?.figure
      ? "54px"
      : "27px"};

  color: ${(props) => (props?.bg === "#773594" ? "white" : "#773594")};
  img {
    width: ${(props) => props?.icoSize?.width}px;
    height: ${(props) => props?.icoSize?.height}px;
    margin-bottom: 35px;
  }
  p {
    margin: 0;
    width: ${(props) => (props?.textSize ? props?.textSize + "px" : "auto")};
  }
  @media (max-width: 1700px) {
    img {
      margin-bottom: 15px;
    }
  }
  @media (min-width: 1550px) {
    font-size: ${(props) =>
      (props?.figure?.width === "32vw" && props?.figure?.height === "32vw") ||
      !props?.figure
        ? "45px"
        : "18px"};
  }
  @media (max-width: 1549px) {
    padding-left: ${(props) =>
      props?.options
        ? ""
        : props?.figure?.width === "32vw" && props?.figure?.height === "32vw"
        ? "45px"
        : "30px"};
    padding-top: ${(props) =>
      props?.options
        ? ""
        : props?.figure?.width === "32vw" && props?.figure?.height === "32vw"
        ? "45px"
        : "30px"};

    font-size: ${(props) =>
      (props?.figure?.width === "32vw" && props?.figure?.height === "32vw") ||
      !props?.figure
        ? "30px"
        : "15px"};
    line-height: ${(props) =>
      (props?.figure?.width === "32vw" && props?.figure?.height === "32vw") ||
      !props?.figure
        ? "40px"
        : "17px"};
    img {
      width: ${(props) => props?.icoSize?.width - 30}px;
      height: ${(props) => props?.icoSize?.height - 30}px;
    }
    p {
      /* background: red; */
      width: ${(props) =>
        props?.textSize
          ? props?.textSize -
            ((props?.figure?.width === "32vw" &&
              props?.figure?.height === "32vw") ||
            !props?.figure
              ? 80
              : 21) +
            "px"
          : "auto"};
    }
  }
  @media (max-width: 991px) {
    animation: none;
    p {
      margin: 0;
      width: ${(props) =>
        props?.textSizeMobile ? props?.textSizeMobile + "px" : "auto"};
    }
    margin-right: ${(props) =>
      (props?.figure?.width === "32vw" && props?.figure?.height === "32vw") ||
      props?.figure?.height === "32vw"
        ? "6px"
        : "0px"};
    width: ${(props) =>
      props?.figure
        ? props?.figure?.width === "32vw"
          ? "90vw"
          : "44vw"
        : "90vw"};
    max-width: ${(props) =>
      props?.figure
        ? props?.figure?.width === "32vw" &&
          props?.figure?.height === "32vw" &&
          !props?.options
          ? "292px"
          : props?.figure?.width === "32vw"
          ? "400px"
          : "198px"
        : "292px"};
    max-height: ${(props) =>
      props?.figure
        ? props?.figure?.height === "32vw"
          ? "384px"
          : "189px"
        : "384px"};
    min-height: ${(props) =>
      props?.figure
        ? props?.figure?.height === "32vw"
          ? "384px"
          : "189px"
        : "384px"};
    height: ${(props) =>
      props?.figure
        ? props?.figure?.height === "32vw"
          ? "90vw"
          : "44vw"
        : "90vw"};
    font-size: ${(props) =>
      (props?.figure?.width === "32vw" && props?.figure?.height === "32vw") ||
      !props?.figure
        ? "32px"
        : "15px"};
    line-height: ${(props) =>
      (props?.figure?.width === "32vw" && props?.figure?.height === "32vw") ||
      !props?.figure
        ? "42px"
        : "22px"};
  }
`;
