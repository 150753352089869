import { ButtonCustom } from "components/form";
import { WhatsappContainer } from "./styles/whatsappSection.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const whatsappIcon = <FontAwesomeIcon icon={faWhatsapp} />;

const WhatsappSection = () => {
  return (
    <WhatsappContainer>
      <h5>Se preferir, converse com a gente pelo whatsapp</h5>
      <a href="https://wa.me/556421422230" target={"_blank"} rel="noreferrer">
        <ButtonCustom
          bg="#3CC839"
          color="white"
          bgDarken={"white"}
          colorDarken={"#2f702e"}
          wd="235px"
          onClick={() => console.log("Hola")}
        >
          {whatsappIcon} fale pelo whatsapp
        </ButtonCustom>
      </a>
    </WhatsappContainer>
  );
};

export default WhatsappSection;
