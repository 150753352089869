import { styles } from "const/styles";
import styled from "styled-components";

export const ContainerArrow = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`;
export const ArrowElement = styled.button`
  width: 52px;
  height: 52px;
  border: none;
  margin-left: 35px;
  background: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  cursor: ${(props) => (props?.active ? "pointer" : "default")};
  :first-child {
    margin-left: 0;
    justify-content: flex-end;
  }
  :hover {
    > div {
      border-color: ${(props) =>
        props?.active ? styles?.color?.secondaryColor : styles?.color?.dark};
      cursor: ${(props) => (props?.active ? "pointer" : "default")};
    }
  }
  > div {
    width: 29px;
    height: 29px;
    border-right: ${(props) =>
      props?.directionT ? "3px solid white" : "none"};
    border-top: ${(props) => (props?.directionT ? "3px solid white" : "none")};
    border-left: ${(props) => (props?.directionT ? "none" : "3px solid white")};
    border-bottom: ${(props) =>
      props?.directionT ? "none" : "3px solid white"};
    transform: rotate(45deg);
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s;
    opacity: ${(props) => (props?.active ? 1 : 0.35)};
    border-color: ${(props) =>
      props?.active ? (props?.bg ? props?.bg : "white") : styles?.color?.dark};
    :hover {
      border-color: ${(props) =>
        props?.active ? styles?.color?.secondaryColor : styles?.color?.dark};
      cursor: ${(props) => (props?.active ? "pointer" : "default")};
    }
    @media (max-width: 991px) {
      :hover {
        width: 29px;
        height: 29px;
        border-color: white;
        cursor: ${(props) => (props?.active ? "pointer" : "default")};
      }
    }
  }
`;
