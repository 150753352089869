import { useMutation, useQuery } from 'react-query';

import axios from 'axios';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

function useBanners() {
  return useQuery('banners', async () => {
    const { data } = await http.get('/banners/');
    return data?.results;
  });
}

function useCreateNotification() {
  return useMutation(async (data) => await http.post('/contacts/', data));
}

export { http, useBanners, useCreateNotification };
