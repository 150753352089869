import logo from "assets/img/header/logo_Momento.svg";
import logoColor from "assets/img/header/logo_color_momento.svg";
import { LogoContainer } from "./styles/logo.styles";
import { useWindowDimensions } from "hooks/dimensions";

const Logo = ({ activeHeader = false }) => {
  const { /*height,*/ width } = useWindowDimensions();
  return (
    <LogoContainer activeHeader={activeHeader}>
      {activeHeader && width > 991 ? (
        <img src={logoColor} alt="Momento" />
      ) : (
        <img src={logo} alt="Momento" />
      )}
    </LogoContainer>
  );
};

export default Logo;
