import { useState, useEffect } from "react";
import {
  SliderFixedContainer,
  ContainerSliderSection,
  TitleSection,
  SlideText,
  SliderElements,
  Element,
  ContainerArrow,
  ImageSlider,
  PointsContainer,
} from "./styles/sliderFixed.styles";
import { ArrowSlider, Container } from "components";
import { useWindowDimensions } from "hooks/dimensions";

const SliderFixed = ({ data }) => {
  const { /*height,*/ width } = useWindowDimensions();
  const [sliderSelected, setSliderSelected] = useState(1);
  const [firstTouch, setFirstTouch] = useState(0);
  const [firstTouchY, setFirstTouchY] = useState(0);
  const [lastTouch, setLastTouch] = useState(0);
  const [lastTouchY, setLastTouchY] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const [activeLeft, setActiveLeft] = useState(false);
  const [activeRight, setActiveRight] = useState(
    (data?.length > 4 && width > 1200) || (data?.length > 3 && width > 991)
      ? true
      : false
  );
  const [maxWidth, setMaxWidth] = useState(
    Math.abs(width > 1349 ? 567 : width >= 1200 ? 519 : width >= 991 ? 402 : 0)
  );

  useEffect(() => {
    if (data?.length > sliderSelected) {
      setActiveRight(true);
    } else {
      setActiveRight(false);
    }
    if (sliderSelected > 1) {
      setActiveLeft(true);
    } else {
      setActiveLeft(false);
    }
  }, [sliderSelected, data?.length]);

  useEffect(() => {
    setMaxWidth(
      Math.abs(
        width > 1349 ? 567 : width >= 1200 ? 519 : width >= 991 ? 402 : 0
      )
    );
  }, [data?.length, width]);

  const DistanceStep = (elementInitial, initialDistance) => {
    let outScreen = Math.abs(maxWidth - elementInitial * data?.length);
    let NewStep = Math.trunc(outScreen / initialDistance);
    return outScreen / NewStep;
  };

  const functionLeft = () => {
    setTranslateX((prevState) => {
      if (prevState >= 0) {
        // setActiveLeft(false);
        // setActiveRight(true);
        return 0;
      } else {
        if (
          prevState +
            DistanceStep(width > 1349 ? 146 : 134, width > 1349 ? 146 : 134) >=
          0
        ) {
          // setActiveLeft(false);
          // setActiveRight(true);
          return 0;
        }
        // setActiveRight(true);
        return (
          prevState +
          DistanceStep(width > 1349 ? 146 : 134, width > 1349 ? 146 : 134)
        );
      }
    });
    if (sliderSelected > 1) {
      setSliderSelected((prevState) => prevState - 1);
    }
    if (sliderSelected < data?.length) {
      // setActiveRight(true);
    }
  };

  const functionRight = () => {
    setTranslateX((prevState) => {
      if (
        (width >= 1350 &&
          data?.length > 4 &&
          prevState <= -(DistanceStep(146, 146) * (data?.length - 4)))|| 
        (width >= 1200 && width < 1350 &&
          data?.length > 4 &&
          prevState <= -(DistanceStep(134, 134) * (data?.length - 4))) ||
        (width < 1200 &&
          data?.length > 3 &&
          prevState <= -(DistanceStep(134, 134) * (data?.length - 3)))
      ) {
        // setActiveLeft(true);
        // setActiveRight(false);
        return prevState;
      } else {
        // if (
        //   prevState - DistanceStep(134, 134) <=
        //   -(DistanceStep(134, 134) * (data?.length - 4))
        // ) {
        //   setActiveRight(false);
        // }
        if (
          prevState -
            DistanceStep(width > 1349 ? 146 : 134, width > 1349 ? 146 : 134) <=
          maxWidth * -1
        ) {
          // setActiveRight(false);
          // setActiveLeft(true);
          return maxWidth * -1;
        }
        // setActiveLeft(true);
        return (
          prevState -
          DistanceStep(width > 1349 ? 146 : 134, width > 1349 ? 146 : 134)
        );
      }
    });
    if (sliderSelected < data?.length) {
      setSliderSelected((prevState) => prevState + 1);
    }
  };

  const getDifference = (initial, final) => {
    if (final < 0) {
      return Math.abs(final) + initial;
    } else {
      return Math.abs(final - initial);
    }
  };

  const TouchEnd = () => {
    let differenceX = getDifference(firstTouch, lastTouch);
    let differenceY = getDifference(firstTouchY, lastTouchY);

    if (differenceX > differenceY) {
      if ((firstTouch > lastTouch) & (lastTouch !== 0)) {
        functionRight();
        // setDirectionT(false);
        // ChangingEffect(0, "right");
      } else if (lastTouch > firstTouch) {
        functionLeft();
        // setDirectionT(true);
        // ChangingEffect(0, "left");
      }
    }

    setLastTouch(0);
    setLastTouchY(0);
    setFirstTouch(0);
    setFirstTouchY(0);
  };

  return (
    <SliderFixedContainer
      onTouchStart={(e) => {
        if (width <= 991) {
          setFirstTouch(e?.nativeEvent?.touches[0]?.clientX);
          setFirstTouchY(e?.nativeEvent?.touches[0]?.clientY);
        }
      }}
      onTouchMove={(e) => {
        if (width <= 991) {
          setLastTouch(e?.nativeEvent?.touches[0]?.clientX);
          setLastTouchY(e?.nativeEvent?.touches[0]?.clientY);
        }
      }}
      onTouchEnd={(e) => {
        if (width <= 991) {
          TouchEnd();
        }
      }}
    >
      <div>
        <ContainerSliderSection>
          <TitleSection>
            <h5>Tudo o que você encontra</h5>
            <h2>na Momento</h2>
          </TitleSection>
          {data?.map((item, index) => (
            <SlideText key={item?.id} active={index + 1 === sliderSelected}>
              <h5>{item?.title}</h5>
              <p>{item?.description}</p>
            </SlideText>
          ))}{" "}
          <ContainerArrow>
            <ArrowSlider
              activeLeft={activeLeft}
              activeRight={activeRight}
              actionLeft={functionLeft}
              actionRight={functionRight}
            />
          </ContainerArrow>
          <SliderElements translateX={translateX}>
            <div>
              {data?.map((item, index) => (
                <Element
                  key={item?.id}
                  onClick={() => setSliderSelected(index + 1)}
                  active={index + 1 === sliderSelected}
                  bg={item?.image}
                />
              ))}
            </div>
          </SliderElements>
          <Container>
            <PointsContainer>
              <div>
                {data?.map((e, index) => (
                  <div
                    key={index}
                    onClick={() => setSliderSelected(index + 1)}
                    className={`divPoint ${
                      sliderSelected === index + 1 ? "active" : ""
                    }`}
                  />
                ))}
              </div>
            </PointsContainer>
          </Container>
        </ContainerSliderSection>
      </div>
      <div>
        {data?.map((item, index) => (
          <ImageSlider
            src={item?.image}
            alt={"Slider momento"}
            active={index + 1 === sliderSelected}
            key={item?.id}
          />
        ))}
      </div>
    </SliderFixedContainer>
  );
};

export default SliderFixed;
