import styled from 'styled-components';

const ButtonCustomContainer = styled.button`
  height: 52px;
  width: ${(props) => (props?.wd ? props?.wd : '100px')};
  color: ${(props) => (props?.color ? props?.color : 'white')};
  background-color: ${(props) => (props?.bg ? props?.bg : 'gray')};
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  border: none;
  padding: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  opacity: ${(props) => (props?.isSubmitting ? 0.5 : 1)};

  ::before,
  ::after {
    content: '';
    position: absolute;
    border-top: 26px solid transparent;
    border-bottom: 26px solid transparent;
    /* height: 52px; */
    transition: all 0.5s ease-in-out;
    top: 0;
  }

  ::before {
    left: -15px;
    border-left: 0px solid transparent;
    border-right: 15px solid ${(props) => (props?.bg ? props?.bg : 'gray')};
  }
  ::after {
    right: -15px;
    border-right: 0px solid transparent;
    border-left: 15px solid ${(props) => (props?.bg ? props?.bg : 'gray')};
  }

  :hover {
    background: ${(props) =>
      props?.colorDarken
        ? props?.colorDarken
        : props?.color
        ? props?.color
        : 'white'};
    color: ${(props) =>
      props?.bgDarken ? props?.bgDarken : props?.bg ? props?.bg : 'gray'};

    ::after {
      border-left: 15px solid
        ${(props) =>
          props?.colorDarken
            ? props?.colorDarken
            : props?.color
            ? props?.color
            : 'white'};
    }
    ::before {
      border-right: 15px solid
        ${(props) =>
          props?.colorDarken
            ? props?.colorDarken
            : props?.color
            ? props?.color
            : 'white'};
    }
  }
  @media (max-width: 991px) {
    :hover {
      color: ${(props) => (props?.color ? props?.color : 'white')};
      background-color: ${(props) => (props?.bg ? props?.bg : 'gray')};
      box-shadow: none;
      ::before {
        border-right: 15px solid ${(props) => (props?.bg ? props?.bg : 'gray')};
      }
      ::after {
        border-left: 15px solid ${(props) => (props?.bg ? props?.bg : 'gray')};
      }
    }
  }
`;
export default ButtonCustomContainer;
