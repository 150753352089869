import { useContext } from "react";
import { MenuContext } from "context";
import { InView } from "react-intersection-observer";

import { InformationFooter } from "components";
import { FooterBase } from "./footer.styles";

const Footer = () => {
  const visible = useContext(MenuContext);
  return (
    <InView
      as="section"
      trackVisibility
      delay={100}
      threshold={0.2}
      onChange={(e) => {
        if (e) {
          visible?.setMenuVisible("contato");
        }
      }}
    >
      <FooterBase>
        <span id={"contato"} />
        <InformationFooter />
      </FooterBase>
    </InView>
  );
};
export default Footer;
