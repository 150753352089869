import { useContext } from "react";
import { MenuContext } from "context";
import { InView } from "react-intersection-observer";
import { SectionContainer } from "./styles";
import { SliderMotivos, TitleMotivos } from "components";
import { data } from "./const.data";

const Motivos = () => {
  const visible = useContext(MenuContext);
  return (
    <InView
      trackVisibility
      delay={100}
      threshold={0.2}
      onChange={(e) => {
        if (e) {
          visible?.setMenuVisible("gestãodeFrota");
        }
      }}
    >
      <SectionContainer>
        <span id={"gestãodeFrota"} />
        <TitleMotivos />
        <SliderMotivos data={data} />
      </SectionContainer>
    </InView>
  );
};

export default Motivos;
