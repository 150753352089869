import styled from "styled-components";
import { styles } from "const/styles";
export const TopContainer = styled.div`
  /* height: 835px; */
  width: 100%;
  min-height: ${(props) => (props?.minWidth ? props?.minWidth : "700px")};
  background-color: white;
  position: relative;
  overflow: hidden;
  z-index: 5;
  @media (max-width: 991px) {
    z-index: 3;
  }
`;

export const ImageSlider = styled.img`
  width: 100%;
  height: 100%;
  /* transform: translateX(100vw); */
  display: ${(props) => (props?.active ? "block" : "none")};
  object-fit: cover;
  /* transition: all 0.5s; */
  /* animation: ${(props) =>
    props?.changing
      ? props?.directionT
        ? "moveTopImgOut 0.35s ease-in-out 0s alternate forwards"
        : "moveTopImgOut2 0.35s ease-in-out 0s alternate forwards"
      : props?.directionT
      ? "moveTopImg 0.35s ease-in-out 0s alternate forwards"
      : "moveTopImg2 0.35s ease-in-out 0s alternate forwards"}; */
  animation: ${(props) =>
    props?.active
      ? "moveTopImg 0.55s ease-in-out 0s alternate forwards"
      : "moveTopImgOut 0.55s ease-in-out 0s alternate forwards"};
  @keyframes moveTopImg {
    0% {
      /* transform: translateX(100vw); */
      opacity: 0;
    }
    100% {
      /* transform: translateX(0px); */
      opacity: 1;
    }
  }
  @keyframes moveTopImgOut {
    0% {
      /* transform: translateX(0); */
      opacity: 1;
    }
    100% {
      /* transform: translateX(-100vw); */
      opacity: 0;
    }
  }
  @keyframes moveTopImg2 {
    0% {
      /* transform: translateX(-100vw); */
      opacity: 0;
    }
    100% {
      /* transform: translateX(0vw); */
      opacity: 1;
    }
  }
  @keyframes moveTopImgOut2 {
    0% {
      /* transform: translateX(0); */
      opacity: 1;
    }
    100% {
      /* transform: translateX(100vw); */
      opacity: 0;
    }
  }
  @media (max-width: 576px) {
    /* max-height: 474px; */
  }
`;

export const ContainerArrow = styled.div`
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  margin: 0 auto;
  position: absolute;
  bottom: 25%;
  right: 0;
  left: 0;
  z-index: 2;
  > div {
    :last-child {
      position: absolute;
      left: 68vw;
      z-index: 2;
    }
  }
  /* > div {
     justify-content: flex-end;
    display: flex; 
    justify-content: center;
    > div {
      :last-child {
         right: 16px;
        z-index: 4;
        position: relative; 
      }
    }
  }
  @media (min-width: 1651px) {
    > div {
      > div {
        :last-child {
          right: 155px;
        }
      }
    }
  }
  @media (max-width: 1650px) {
    > div {
      > div {
        :last-child {
          right: 250px;
        }
      }
    }
  } */
  @media (max-width: 991px) {
    position: relative;
    margin-bottom: 50px;
    margin-top: 20px;
    > div {
      :last-child {
        display: none;
      }
    }
    /* > div {
      > div {
        :last-child {
          display: none;
        }
      }
    } */
  }
`;

export const Container = styled.div`
  max-width: 1250px;
  margin: 0 auto;
  position: relative;
  /* background: red; */
  height: 50px;
  bottom: 240px;
`;

export const PointsContainer = styled.div`
  width: 100%;
  height: 24px;
  z-index: 2;
  position: absolute;
  align-items: center;
  justify-content: center;
  bottom: calc(50% - 12px);
  > div {
    display: flex;
    justify-content: center;
    height: 100%;
    .divPoint {
      display: inline-block;
      width: 15px;
      height: 15px;
      box-sizing: border-box;
      padding: 0;
      border: 1px solid ${styles?.color?.light};
      border-radius: 50%;
      margin-right: 10px;
      margin-top: auto;
      margin-bottom: auto;
      background: transparent;
      position: relative;
      cursor: pointer;
      &.active {
        ::after {
          content: "";
          width: 9px;
          height: 9px;
          position: absolute;
          border-radius: 50%;
          top: calc(50% - 4.5px);
          left: calc(50% - 4.5px);
          background: ${styles?.color?.secondaryColor};
        }
      }
      :last-child {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 991px) {
    > div {
      max-width: 105px;
      margin: 0 auto;
      .divPoint {
        border: 1px solid ${styles?.color?.primaryColor};
      }
    }
  }
`;
