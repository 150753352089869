import styled from "styled-components";
import { styles } from "const/styles";

export const LogoContainer = styled.div`
  width: 229px;
  height: 53px;
  position: relative;
  transition: all 0.5s;
  img {
    width: 229px;
    height: 53px;
    z-index: 2;
    position: absolute;
  }
  ::after {
    content: "";
    transition: all 1s;
    background: ${(props) =>
      props?.activeHeader ? "none" : styles?.color?.primaryColor};
    width: ${(props) => (props?.activeHeader ? "0" : "537px")};
    height: ${(props) => (props?.activeHeader ? "0" : "555px")};
    position: absolute;
    left: calc(50% - 268px);
    top: -482px;
    transform: rotate(45deg);
    z-index: 1;
  }
  @media (max-width: 991px) {
    img {
      width: 138px;
      height: 32px;
      display: ${(props) => (props?.activeHeader ? "none" : "block")};
      margin-left: 42px;
      margin-top: 17px;
    }
    ::after {
      content: "";
      transition: all 0.5s;
      background: ${(props) =>
        props?.activeHeader ? "none" : styles?.color?.primaryColor};
      width: ${(props) => (props?.activeHeader ? "0" : "335px")};
      height: ${(props) => (props?.activeHeader ? "0" : "290px")};
      position: absolute;
      left: calc(50% - 185px);
      top: -241px;
      transform: rotate(45deg);
      z-index: 1;
    }
  }
`;
