import styled from "styled-components";
import { styles } from "const/styles";

export const FormContainer = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-bottom: 70px;
  }
`;

export const BoxContainer = styled.div`
  max-width: 618px;
  margin-top: ${(props) => (props?.mt ? props?.mt : "49px")};

  .form-success,
  h5 {
    font-family: Inter;
    font-style: normal;
    font-size: 30px;
  }

  h5 {
    font-weight: 300;
    color: #51575c;
    margin: 0;
    line-height: 35px;
  }

  .form-success {
    color: #3cc839;

    .send,
    .message {
      margin: 0;
    }

    .send {
      font-weight: 700;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-left: 11px;
      font-size: 25px;
      color: ${styles?.color?.secondaryColor};
    }
  }

  .selectContainer {
    select {
      width: 100%;
    }
    &.state {
      width: 100%;
      max-width: 194px;
      margin-right: 31px;
      display: inline-block;
    }
    &.quantity {
      width: 100%;
      max-width: 393px;
      display: inline-block;
    }
    span {
      display: block;
    }
  }

  @media (max-width: 1349px) {
    .selectContainer {
      select {
        width: 100%;
      }
      &.state {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
      }
      &.quantity {
        width: 100%;
        max-width: 100%;
        margin-top: 44px;
      }
    }
  }

  @media (max-width: 991px) {
    button {
      margin-left: 15px;
    }
    h5 {
      font-family: Inter;
      font-style: normal;
      font-weight: 300;
      font-size: 25px;
      line-height: 35px;
    }
  }
`;
export const RadioSection = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 44px;
  input {
    width: 27px;
    height: 27px;
    opacity: 0;
    z-index: 2;
    top: 9px;
    margin-right: 11px;
    position: relative;
    cursor: pointer;
    & + label {
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      /* Styles these pseudo elements to look like radio inputs. */
      &::before {
        content: "";
        position: absolute;
        color: #f5f5f5;
        text-align: center;
        border-radius: 0;
        top: 0;
        left: -39px;
        width: 27px;
        height: 27px;
        transition: all 0.5s ease-in-out;
        cursor: pointer;
      }
      &::before {
        z-index: 0;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: 1rem;
        border-radius: 0;
        background-color: #ffffff;
        color: #ffffff;
        cursor: pointer;
        border: 1px solid ${styles?.color?.primaryColor};
      }
    }

    &:focus,
    &:hover,
    &:checked {
      & + label::before {
        cursor: pointer;
        animation-duration: 0.3s;
        animation-name: selectCheckboxAnim;
        animation-iteration-count: 1;
        animation-direction: alternate;
        background-color: ${styles?.color?.secondaryColor};
        border: solid 1px ${styles?.color?.primaryColor};
        box-shadow: inset 0 0 0 4px white, inset 0 0 0 0 #ffffff,
          inset 0 0 1px 2px ${styles?.color?.secondaryColor};
      }
    }
    &:not(:checked) {
      & + label::before {
        background-color: white;
      }
    }

    & + label {
      &::before {
        border-radius: 50%;
      }
    }

    /* Add some animations like a boss, cause why would you hustle to build
  a custom component when you can't touch this!
  */
    @keyframes selectCheckboxAnim {
      0% {
        box-shadow: 0 0 0 0 white, inset 0 0 0 2px #ffffff,
          inset 0 0 0 3px ${styles?.color?.secondaryColor},
          inset 0 0 0 16px #ffffff,
          inset 0 0 0 16px ${styles?.color?.secondaryColor};
      }

      100% {
        box-shadow: 0 0 20px 8px #eeddee, inset 0 0 0 0 white,
          inset 0 0 0 1px white, inset 0 0 0 0 #ffffff,
          inset 0 0 0 16px ${styles?.color?.secondaryColor};
      }
    }
  }
  label {
    position: relative;
    height: 27px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: ${styles?.color?.primaryColor};
  }
  @media (max-width: 991px) {
    animation: none;
    margin-right: 24px;
  }
`;
