import styled from "styled-components";

export const HeaderContainer = styled.header`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 33px;
  z-index: 10;
  transition: all 0.5s;
  > div {
    max-width: 100vw;
    /* min-width: 100vw; */
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.active {
    > div {
      min-width: 1250px;
      max-width: 1250px;
      justify-content: space-between;
    }

    padding-top: 0;
    height: 73px;
    background-color: white;
    box-shadow: 1px 7px 12px rgba(0, 0, 0, 0.04);
  }
  @media (max-width: 1249px) {
    > div {
      width: calc(100% - 80px);
    }

    &.active {
      > div {
        min-width: auto;
      }
    }
  }
  @media (max-width: 991px) {
    padding-top: 0;
    > div {
      justify-content: space-between;
      width: 100%;
    }
    &.active {
      > div {
        position: static;
      }
      transition: all 0.5s;
      padding-top: 0;
      height: 73px;
      background: none;
      box-shadow: none;
    }
    display: block;
  }
`;
