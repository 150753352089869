import styled from "styled-components";
import { styles } from "const/styles";

export const BodyFalitaContainer = styled.div`
  height: 605px;
  background-color: ${styles?.color?.lightDark};
  color: ${styles?.color?.light};
  padding-top: 46px;
  box-sizing: border-box;
  position: relative;
  p {
    width: 434px;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
  }
  > div {
    width: calc(100% - 80px);
    box-sizing: border-box;
    margin: 0 auto;
    > div {
      :first-child {
        z-index: 2;
      }
    }
  }
  @media (max-width: 991px) {
    padding-top: 227px;
    height: 753px;
    p {
      margin: 0 auto;
      width: 285px;
    }
  }
`;

export const ContainerFigure = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 25vw;
  max-height: 509px;
  top: -12.5vw;
  z-index: 1;
  justify-content: center;
  @media (min-width: 2027px) {
    top: -254.5px;
  }
  @media (max-width: 991px) {
    width: auto;
    height: 33vw;
    max-height: 33vw;
    top: -16.5vw;
  }

  @media (max-width: 767px) {
    /* height: 55vw;
    max-height: 55vw;
    top: -27.5vw; */
    height: 55vw;
    max-height: 55vw;
    top: -27.5vw;
  }

  @media (max-width: 576px) {
    /* background-color: red; */
  }
`;

export const Box = styled.div`
  width: 25vw;
  height: 25vw;
  max-width: 509px;
  max-height: 509px;
  background-color: ${(props) =>
    props?.transparent ? "transparent" : props?.color ? props?.color : "white"};
  margin-left: ${(props) => (props?.ml ? "241px" : "0")};
  transform: rotate(45deg);
  z-index: 2;
  :last-child {
    z-index: 1;
    max-width: 414px;
    position: relative;
  }
  > div {
    width: 100%;
    height: 100%;
    position: relative;
    ::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 50vw;
      top: -49vw;
      background: ${(props) =>
        props?.transparent
          ? "transparent"
          : props?.color
          ? props?.color
          : "white"};
    }
  }
  img {
    transform: rotate(-45deg);
  }
  @media (min-width: 1800px) {
    top: 20px;
  }
  @media (max-width: 991px) {
    width: 33vw;
    height: 33vw;
    margin-left: ${(props) => (props?.ml ? "25vw" : "0")};
    :first-child {
      display: none;
    }
  }
  @media (max-width: 767px) {
    width: 55vw;
    height: 55vw;
    margin-left: ${(props) => (props?.ml ? "-10vw" : "0")};
    :nth-child(2) {
      width: 55vw;
      height: 55vw;
      margin-left: -16vw;
    }
    :last-child {
      width: 70vw;
      height: 70vw;
      margin-left: 25vw;
      margin-left: 18vw;
      position: absolute;
      left: 47vw;
      top: -14vw;
    }
  }
  @media (max-width: 576px) {
    /* width: 70vw;
    height: 70vw; */
  }
`;

export const CarImage = styled.img`
  width: 50vw;
  min-width: 428px;
  max-width: 997px;
  /* height: 25vw;; */
  max-height: 450px;
  top: -40px;
  left: -160px;
  position: absolute;
  @media (max-width: 1540px) {
    top: -25px;
    left: -140px;
  }
  @media (max-width: 991px) {
    top: -8px;
    left: -38px;
  }
  @media (max-width: 767px) {
    width: 75vw;
    top: -67px;
    left: 84px;
  }
  @media (max-width: 470px) {
    width: 75vw;
    top: -22vw;
    left: -1vw;
  }
`;

export const UserImage = styled.img`
  width: 15vw;
  min-width: 107px;
  max-width: 245px;
  top: -3vw;
  left: -4vw;
  position: relative;
  @media (max-width: 991px) {
    top: 7vw;
    left: 0vw;
  }
  @media (max-width: 767px) {
    top: 0vw;
    left: 24vw;
  }
  @media (max-width: 470px) {
    top: -20vw;
    left: 15vw;
  }
`;
