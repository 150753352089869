import { HeaderComponent, Top } from "components";

import { InView } from "react-intersection-observer";
import { MenuContext } from "context";
import Skeleton from "react-loading-skeleton";
import { useBanners } from "hooks/use-services";
import { useContext } from "react";

const Header = () => {
  const visible = useContext(MenuContext);
  const { data, isLoading } = useBanners();

   if (isLoading) {
     return (
       <div aria-label='loading'>
         <Skeleton height='100vh' />
       </div>
     );
   }

  return (
    <>
      <HeaderComponent />
      <InView
        trackVisibility
        delay={100}
        threshold={0.7}
        onChange={(e) => {
          if (e) {
            visible?.setMenuVisible("top");
          }
        }}
      >
        <span id="top" />
        <Top data={data} />
      </InView>
    </>
  );
};

export default Header;
