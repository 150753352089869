import { QueryClientProvider as QCProvider, QueryClient } from 'react-query';

import { ReactQueryDevtools } from 'react-query/devtools';

function QueryClientProvider({ children, queryOptions }) {
  const retry = ['development', 'production'].includes(process.env.NODE_ENV);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry,
        ...queryOptions,
      },
    },
  });

  return (
    <QCProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QCProvider>
  );
}

export { QueryClientProvider };
