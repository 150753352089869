import { Container } from "components";
import { HeaderContainer } from "./styles/header.styles";
import Logo from "./logo";
import Menu from "./menu";
import MenuMobile from "./menuMobile";
import { useState } from "react";

const options = [
  { id: "gestãodeFrota", name: "Gestão de Frota" },
  { id: "sobreNós", name: "Sobre Nós" },
  {
    id: "diferenciais",
    name: "Diferenciais",
    options: [{ id: "option1", option: "option1" }],
  },
  { id: "cotação", name: "Cotação" },
  { id: "contato", name: "Contato" },
];

const Header = () => {
  const [headerClass, setHeaderClass] = useState(false);
  const [activeMenuMobile, setActiveMenuMobile] = useState(false);
  const [animationMenu, setAnimationMenu] = useState(false);

  const changeHeaderClass = () => {
    if (window?.scrollY > 1) {
      if (!headerClass) {
        setHeaderClass(true);
      }
    } else {
      setHeaderClass(false);
    }
  };

  window.addEventListener("scroll", changeHeaderClass);

  return (
    <HeaderContainer className={headerClass ? "active" : ""}>
      <Container style={{ display: "flex", minWidth: "1250px" }}>
        <Logo activeHeader={headerClass} />
        <Menu
          options={options}
          activeHeader={headerClass}
          setActiveMenuMobile={setActiveMenuMobile}
          setAnimationMenu={setAnimationMenu}
        />
        <MenuMobile
          options={options}
          activeMenuMobile={activeMenuMobile}
          setActiveMenuMobile={setActiveMenuMobile}
          animationMenu={animationMenu}
        />
      </Container>
    </HeaderContainer>
  );
};

export default Header;
