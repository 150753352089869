import styled from "styled-components";
import { styles } from "const/styles";
export const TitleMotivosContainer = styled.div`
  @media (max-width: 1350px) {
    > div {
      width: calc(100% - 80px);
    }
  }
`;
export const ContainerTitle = styled.div`
  /* margin-left: 16vw; */
  h2,
  p {
    margin: 0;
    width: 467px;
  }
  p {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    color: #000;
  }
  h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 65px;
    line-height: 57px;
    width: 467px;
    color: ${styles?.color?.primaryColor};
  }
  @media (max-width: 991px) {
    /* margin-left: 40px; */
    margin-bottom: 50px;
    p {
      font-size: 20px;
      line-height: 35px;
      width: 265px;
    }
    h2 {
      font-size: 40px;
      line-height: 40px;
      width: 288px;
    }
  }
`;

export const WhatsappSection = styled.a`
  position: fixed;
  z-index: 3;
  left: 69vw; bottom: 30px;
  @media (max-width: 991px) {
    top: auto;
    left: calc(50% - 117.5px)
  }
`;
