import * as yup from "yup";

import {
  BoxContainer,
  FormContainer,
  RadioSection,
} from "./styles/contactForm.style";
import {
  ButtonCustom,
  ErrorMessage,
  InputCustom,
  SelectCustom,
} from "components/form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brasilStates } from "utils/states";
import { faChevronRight } from "@fortawesome/fontawesome-free-solid";
import { styles } from "const/styles";
import { useCreateNotification } from "hooks/use-services";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const FIELD_REQUIRED_MESSAGE = "Este campo é obrigatório";
const schema = yup
  .object({
    full_name: yup.string().required(FIELD_REQUIRED_MESSAGE),
    email: yup
      .string()
      .email("e-mail inválido")
      .required(FIELD_REQUIRED_MESSAGE),
    telephone: yup.string().required(FIELD_REQUIRED_MESSAGE),
    company: yup.string().required(FIELD_REQUIRED_MESSAGE),
    city: yup.string().required(FIELD_REQUIRED_MESSAGE),
    state: yup.string().required(FIELD_REQUIRED_MESSAGE),
    message: yup.string().required(FIELD_REQUIRED_MESSAGE),
    contact_type: yup.string().required(FIELD_REQUIRED_MESSAGE),
    number_vehicles_per_fleet: yup.string().required(FIELD_REQUIRED_MESSAGE),
  })
  .required();

const angleRight = <FontAwesomeIcon icon={faChevronRight} />;

const numberOfVehiclesPerFleet = [
  { id: "0-20", label: "0 - 20" },
  { id: "20-50", label: "20 - 50" },
  { id: "50-100", label: "50 - 100" },
  { id: "Acima_de_100", label: "Acima de 100" },
];

function ContactForm() {
  const { mutate, isLoading, isSuccess } = useCreateNotification();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const isSubmitting = isLoading || Object.keys(errors).length;

  function onSubmit(data, evt) {
    mutate(data);
    evt.target.reset();
  }

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <BoxContainer mt="0">
        <InputCustom>
          <input type={"text"} placeholder="Nome" {...register("full_name")} />
          <label>
            Nome<span className="required">*</span>
          </label>
        </InputCustom>
        <ErrorMessage>{errors.full_name?.message}</ErrorMessage>
      </BoxContainer>
      <BoxContainer>
        <InputCustom>
          <input type={"email"} placeholder="E-mail" {...register("email")} />
          <label>
            E-mail<span className="required">*</span>
          </label>
        </InputCustom>
        <ErrorMessage>{errors.email?.message}</ErrorMessage>
      </BoxContainer>
      <BoxContainer>
        <InputCustom>
          <input
            type={"text"}
            placeholder="Telefone"
            {...register("telephone")}
          />
          <label>
            Telefone<span className="required">*</span>
          </label>
        </InputCustom>
        <ErrorMessage>{errors.telephone?.message}</ErrorMessage>
      </BoxContainer>
      <BoxContainer>
        <InputCustom placeholder="Empresa">
          <input type={"text"} placeholder="Empresa" {...register("company")} />
          <label>
            Empresa<span className="required">*</span>
          </label>
        </InputCustom>
        <ErrorMessage>{errors.company?.message}</ErrorMessage>
      </BoxContainer>
      <BoxContainer>
        <InputCustom placeholder="Cidade">
          <input type={"text"} placeholder="Cidade" {...register("city")} />
          <label>
            Cidade<span className="required">*</span>
          </label>
        </InputCustom>
        <ErrorMessage>{errors.city?.message}</ErrorMessage>
      </BoxContainer>
      <BoxContainer>
        <div className="selectContainer state">
          <SelectCustom {...register("state")}>
            <option value="">Estado</option>
            {brasilStates?.map((state) => (
              <option key={state}>{state}</option>
            ))}
          </SelectCustom>
          <ErrorMessage>{errors.state?.message}</ErrorMessage>
        </div>
        <div className="selectContainer quantity">
          <SelectCustom
            {...register("number_vehicles_per_fleet")}
          >
            <option value="">Quantidade de Veículos na Frota</option>
            {numberOfVehiclesPerFleet?.map(({ id, label }) => (
              <option value={id} key={id}>
                {label}
              </option>
            ))}
          </SelectCustom>
          <ErrorMessage>
            {errors.number_vehicles_per_fleet?.message}
          </ErrorMessage>
        </div>
      </BoxContainer>
      <BoxContainer mt="44px">
        <InputCustom placeholder="Mensagem">
          <input
            type={"text"}
            placeholder="Mensagem"
            {...register("message")}
          />
          <label>
            Mensagem<span className="required">*</span>
          </label>
        </InputCustom>
        <ErrorMessage>{errors.message?.message}</ErrorMessage>
      </BoxContainer>
      <BoxContainer mt="62px">
        <h5>Como você prefere nosso contato?</h5>
      </BoxContainer>
      <BoxContainer mt="15px">
        <RadioSection>
          <input
            defaultChecked
            type="radio"
            value="email"
            name="radioData"
            {...register("contact_type")}
          />
          <label>
            E-mail<span className="required">*</span>
          </label>
        </RadioSection>
        <RadioSection>
          <input
            type="radio"
            value="phone"
            name="radioData"
            {...register("contact_type")}
          />
          <label>
            Telefone<span className="required">*</span>
          </label>
        </RadioSection>
      </BoxContainer>
      <BoxContainer mt="42px">
        <ButtonCustom
          type="submit"
          wd="145px"
          bgDarken={"white"}
          colorDarken={"#40234c"}
          bg={styles?.color?.primaryColor}
          isSubmitting={isSubmitting}
        >
          Enviar {angleRight}
        </ButtonCustom>
      </BoxContainer>
      {isSuccess && (
        <BoxContainer mt="28px" role="alert-success">
          <div className="form-success">
            <p className="send">Enviado.</p>
            <p className="message">Em breve entraremos em contato.</p>
          </div>
        </BoxContainer>
      )}
    </FormContainer>
  );
}

export default ContactForm;
