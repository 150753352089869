import styled from "styled-components";
import { styles } from "const/styles";
export const HeaderFacilitaContainer = styled.div`
  height: 630px;
  background-color: ${styles?.color?.light};
  color: ${styles?.color?.lightDark};
  padding-top: 293px;
  box-sizing: border-box;
  position: relative;
  h2,
  h5 {
    margin: 0;
  }
  h2 {
    width: 364px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 65px;
    line-height: 57px;
    color: ${styles?.color?.primaryColor};
  }
  h5 {
    margin-bottom: 21px;
    width: 397px;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    color: ${styles?.color?.lightDark};
  }
  > div {
    width: calc(100% - 80px);
    box-sizing: border-box;
    margin: 0 auto;
  }
  @media (max-width: 991px) {
    padding-top: 100px;
    height: 496px;
    h2 {
      width: 288px;
      font-size: 40px;
      line-height: 40px;
      z-index: 2;
      position: absolute;
    }
    h5 {
      width: 265px;
      font-size: 20px;
      line-height: 28px;
    }
  }
`;
