import motivo1 from "assets/img/motivos/motivo_momento_1.svg";
import motivo2_1 from "assets/img/motivos/motivo_momento_2.1.svg";
import motivo2_2 from "assets/img/motivos/motivo_momento_2.2.svg";
import motivo2_3 from "assets/img/motivos/motivo_momento_2.3.svg";
import motivo3 from "assets/img/motivos/motivo_momento_3.svg";
//second block
import motivo4_1 from "assets/img/motivos/motivo_momento_4.1.svg";
import motivo4_2 from "assets/img/motivos/motivo_momento_4.2.svg";
import motivo5 from "assets/img/motivos/motivo_momento_5.svg";
import motivo6 from "assets/img/motivos/motivo_momento_6.svg";
import motivo7 from "assets/img/motivos/motivo_momento_7.svg";
import motivo8 from "assets/img/motivos/motivo_momento_8.svg";

const figure = {
  box: { width: "32vw", height: "32vw" },
  horizontal: { width: "32vw", height: "15.5vw" },
  vertical: { width: "15.5vw", height: "32vw" },
  littleBox: { width: "15.5vw", height: "15.5vw" },
};

export const data = [
  [
    {
      id: "mt1",
      text: "Investimento zero em aquisição",
      text1: "de frota",
      align_self: "auto",
      ico: motivo1,
      icoSize: { width: "128", height: "117" },
      textSize: "377",
      textSizeMobile: "240",
      figure: figure["box"],
      color: "#E5E5EB",
      translateData: true,
      translateDataX: "100",
      translateDataY: "0",
    },
    {
      id: "mt2",
      translateData: false,
      options: [
        {
          id: "mt2_1",
          text: "Utilização mais inteligente do recurso livre",
          align_self: "auto",
          ico: motivo2_1,
          icoSize: { width: "108", height: "92" },
          textSize: "140",
          textSizeMobile: "200",
          figure: figure["horizontal"],
          color: "#E5E5EB",
          translateData: true,
          translateDataX: "-100",
          translateDataY: "0",
        },
        {
          id: "mt2_2",
          text: "Frota sempre nova e revisada",
          align_self: "end",
          ico: motivo2_2,
          icoSize: { width: "96", height: "62" },
          textSize: "114",
          textSizeMobile: "109",
          figure: figure["littleBox"],
          color: "#773594",
          translateData: true,
          translateDataX: "100",
          translateDataY: "0",
        },
        {
          id: "mt2_3",
          text: "Não se preocupar com a logística",
          align_self: "end",
          ico: motivo2_3,
          icoSize: { width: "102", height: "72" },
          textSize: "126",
          textSizeMobile: "109",
          figure: figure["littleBox"],
          color: "#6BF9E1",
          translateData: true,
          translateDataX: "0",
          translateDataY: "-100",
        },
      ],
      figure: figure["box"],
      align_self: "auto",
    },
    {
      id: "mt3",
      text: "Renovação da frota a cada novo contrato",
      align_self: "auto",
      ico: motivo3,
      icoSize: { width: "120", height: "115" },
      textSize: "300",
      textSizeMobile: "175",
      figure: figure["box"],
      color: "#773594",
      whatsapp: true,
      translateData: true,
      translateDataX: "-100",
      translateDataY: "0",
    },
  ],
  [
    {
      id: "m2t1",
      figure: figure["vertical"],
      align_self: "auto",
      translateData: false,
      options: [
        {
          id: "m2t1_1",
          text: "Melhor planejamento tributário",
          align_self: "auto",
          ico: motivo4_1,
          icoSize: { width: "105", height: "75" },
          textSize: "120",
          textSizeMobile: "100",
          figure: figure["littleBox"],
          color: "#53F9E0",
          translateData: true,
          translateDataX: "-10",
          translateDataY: "100",
        },
        {
          id: "m2t1_2",
          text: "Maior economia total",
          align_self: "end",
          ico: motivo4_2,
          icoSize: { width: "101", height: "72" },
          textSize: "135",
          textSizeMobile: "115",
          figure: figure["littleBox"],
          color: "#773594",
          translateData: true,
          translateDataX: "-30",
          translateDataY: "-100",
        },
      ],
    },
    {
      id: "m2t2",
      text: "Custo zero com depreciação",
      align_self: "auto",
      ico: motivo5,
      icoSize: { width: "102", height: "114" },
      textSize: "335",
      textSizeMobile: "315",
      figure: figure["box"],
      color: "#E5E5EB",
      translateData: true,
      translateDataX: "-50",
      translateDataY: "0",
    },
    {
      id: "m2t3",
      text: "Risco zero de interrupção da atividade da empresa por falta de veículo",
      align_self: "auto",
      ico: motivo6,
      icoSize: { width: "95", height: "95" },
      textSize: "135",
      textSizeMobile: "115",
      figure: figure["vertical"],
      color: "#773594",
      translateData: true,
      translateDataX: "0",
      translateDataY: "100",
    },
    {
      id: "m2t4",
      figure: figure["box"],
      align_self: "auto",
      translateData: false,
      options: [
        {
          id: "m2t4_1",
          text: "Operação muito mais fácil sem gestão de IPVA, licenciamento, manutenção, multas, aquisições, documentação, seguro",
          align_self: "auto",
          ico: motivo7,
          icoSize: { width: "81", height: "83" },
          textSize: "350",
          textSizeMobile: "300",
          figure: figure["horizontal"],
          color: "#E5E5EB",
          translateData: true,
          translateDataX: "0",
          translateDataY: "-100",
        },
        {
          id: "m2t4_2",
          text: "Carro reserva em caso de manutenção",
          align_self: "end",
          ico: motivo8,
          icoSize: { width: "71", height: "107" },
          textSize: "220",
          textSizeMobile: "185",
          figure: figure["horizontal"],
          color: "#6BF9E1",
          translateData: true,
          translateDataX: "-100",
          translateDataY: "0",
        },
      ],
    },
  ],
];
