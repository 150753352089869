import {
  ContainerMenuMobile,
  ContainerButton,
  ContainerOptions,
} from "./styles/menuMobile.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const icon = <FontAwesomeIcon icon={faXmark} />;

const MenuMobile = ({
  activeMenuMobile = false,
  options = [],
  setActiveMenuMobile,
  animationMenu = false,
}) => {
  return (
    <ContainerMenuMobile
      active={activeMenuMobile}
      animationMenu={animationMenu}
    >
      <ContainerButton>
        <button onClick={() => setActiveMenuMobile(false)}>{icon}</button>
      </ContainerButton>
      <ContainerOptions>
        {options?.map((item) => {
          return (
            <li key={item?.id} onClick={() => setActiveMenuMobile(false)}>
              <a href={`#${item?.id}`}>{item?.name}</a>
            </li>
          );
        })}
      </ContainerOptions>
    </ContainerMenuMobile>
  );
};

export default MenuMobile;
