import styled from "styled-components";
import { styles } from "const/styles";
import frameImage from "assets/img/footer/Frame.png";

export const InformationFooterContainer = styled.div`
  background-color: ${styles?.color?.primaryColor};
  position: relative;
  height: 876px;
  box-sizing: border-box;
  padding-top: 40px;
  > div {
    width: calc(100% - 80px);
    margin: 0 auto;
    box-sizing: border-box;
    :first-child {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      > div {
        ::before {
          content: "";
          position: absolute;
          width: 72.58px;
          height: 72.58px;
          background-color: ${styles?.color?.primaryColor};
          top: -36.29px;
          right: 12px;
          transform: rotate(45deg);
        }
      }
    }
    :last-child {
      padding: 229px 0px 0;
      background-image: url(${frameImage});
      height: calc(100% - 60px);
    }
  }
  @media (max-width: 991px) {
    > div {
      :first-child {
        > div {
          ::before {
            width: 36.29px;
            height: 36.29px;
            top: -18.145px;
            right: 7px;
          }
        }
      }
      :last-child {
        padding: 100px 0px 0;
      }
    }

    .logoFooter {
      width: 174px;
    }
  }
`;

export const CarImage = styled.img`
  top: -444px;
  left: -40px;
  position: absolute;
  @media (max-width: 991px) {
    width: 70%;
    top: -320px;
    left: -35px;
  }
  @media (max-width: 767px) {
    top: -220px;
    left: -20px;
  }
  @media (max-width: 576px) {
    top: -200px;
    left: -25px;
  }
  @media (max-width: 450px) {
    /* top: -150px; */
  }
`;
export const BodyInformation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 133px;
  @media (max-width: 1200px) {
    img {
      max-width: 550px;
    }
  }
  @media (max-width: 991px) {
    flex-direction: column;
    margin-bottom: 55px;
    div {
      order: 2;
      align-self: flex-start;
    }
    img {
      width: 100%;
      min-width: 309px;
      order: 1;
      margin-bottom: 83px;
    }
  }
`;

export const InformationBox = styled.div`
  width: 382px;
  p {
    font-family: inter;
    font-weight: 300;
    display: block;
    width: 372px;
    size: 16px;
    line-height: 25.68px;
    color: ${styles.color.light};
    margin: 0;
    span {
      display: block;
    }
    .tel {
      font-weight: 600;
    }
    .email {
      margin-top: 30px;
    }
  }
  div {
    margin-top: 30px;
    a {
      width: 50px;
      height: 50px;
      display: inline-flex;
      margin-right: 20px;
      justify-content: center;
      align-items: center;
      background-color: ${styles?.color?.secondaryColor};
      border: 1px solid ${styles?.color?.secondaryColor};
      transition: all 0.5s ease-in-out;
      svg {
        width: 34px;
        height: 34px;
        transition: all 0.5s ease-in-out;
        color: ${styles?.color?.primaryColor};
      }

      :hover {
        background: ${styles?.color?.primaryColor};
        color: ${styles?.color?.primaryColor};
        svg {
          color: ${styles?.color?.secondaryColor};
        }
      }
    }
  }
  @media (max-width: 991px) {
    div {
      a {
        :hover {
          background: ${styles?.color?.secondaryColor};
          color: ${styles?.color?.secondaryColor};
          svg {
            color: ${styles?.color?.primaryColor};
          }
        }
      }
    }
    p {
      width: 246px;
    }
  }

  @media (max-width: 576px) {
    width: 100%;
    
  }
`;
