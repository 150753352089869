import {
  ContactFormContainer,
  InformationContainer,
  TextContainer,
} from "./styles/contactFormContainer.styles";
import { useWindowDimensions } from "hooks/dimensions";
import { Container } from "../";
import ContactForm from "./contactForm";
import WhatsappSection from "./whatsappSection";

const ContactFormComponent = () => {
  const { /*height,*/ width } = useWindowDimensions();
  return (
    <ContactFormContainer>
      <div>
        <Container>
          <InformationContainer>
            <div>
              <TextContainer>
                <h2>Solicite um orçamento</h2>
                <h5>de terceirização da sua frota:</h5>
              </TextContainer>
              {width > 991 ? <WhatsappSection /> : ""}
            </div>
            <div>
              <ContactForm />
              {width > 991 ? "" : <WhatsappSection />}
            </div>
          </InformationContainer>
        </Container>
      </div>
    </ContactFormContainer>
  );
};

export default ContactFormComponent;
