import {
  ButtonMobile,
  ContainerMenu,
  ContainerOptions,
} from "./styles/menu.styles";
import React, { useContext } from "react";
import { faAngleUp, faBars } from "@fortawesome/fontawesome-free-solid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuContext } from "context";

const icon = <FontAwesomeIcon icon={faBars} />;
const iconUp = <FontAwesomeIcon icon={faAngleUp} />;

const Menu = ({
  activeHeader = false,
  setActiveMenuMobile,
  options = [],
  setAnimationMenu,
}) => {
  const theme = useContext(MenuContext);

  return (
    <ContainerMenu>
      <ContainerOptions activeHeader={activeHeader}>
        {options?.map((item) => {
          return (
            <li
              key={item?.id}
              className={theme?.menuVisible === item?.id ? "visible" : ""}
              onClick={() => {
                // if (width <= 991) {
                // setActiveMenu(false);
                // }
              }}
            >
              <a href={`#${item?.id}`}>{item?.name}</a>
              <span>{iconUp}</span>
            </li>
          );
        })}
      </ContainerOptions>
      <ButtonMobile
        onClick={() => {
          setActiveMenuMobile(true);
          setAnimationMenu(true);
        }}
      >
        {icon}
      </ButtonMobile>
    </ContainerMenu>
  );
};

export default Menu;
