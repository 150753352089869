import styled from "styled-components";

export const WhatsappContainer = styled.div`
  a {
    text-decoration: none;
  }
  h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    color: #51575c;
    margin: 0;
    margin-bottom: 35px;
    width: 364px;
  }
  button {
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 11px;
      font-size: 25px;
    }
  }
  @media (max-width: 991px) {
    h5 {
      font-family: Inter;
      font-style: normal;
      font-weight: 300;
      font-size: 25px;
      line-height: 35px;
      width: 260px;
    }
  }
`;
