import { useContext } from "react";
import { MenuContext } from "context";
import { InView } from "react-intersection-observer";
import { PlayerSection } from "./style";
import { SliderFixed } from "components";
import imageOne from "assets/img/playerFixed/playerFixed-Momento-1.png";
import imageTwo from "assets/img/playerFixed/playerFixed-Momento-2.png";
import imageThree from "assets/img/playerFixed/playerFixed-Momento-3.png";
import imageFour from "assets/img/playerFixed/playerFixed-Momento-4.png";
import imageFive from "assets/img/playerFixed/playerFixed-Momento-5.png";
import imageSix from "assets/img/playerFixed/playerFixed-Momento-6.png";

const data = [
  {
    id: "fx-1",
    title: "Atendimento Personalizado Premium",
    description:
      "você é atendido por um analista de frota dedicado e não por central de atendimento",
    image: imageOne,
  },
  {
    id: "fx-2",
    title: "Delivery Brasil",
    description: "Realizamos a entrega das frotas em todo território nacional",
    image: imageTwo,
  },
  {
    id: "fx-3",
    title: "Opção de Cobertura de Risco",
    description:
      "Você tem a alternativa de proteger o veículo locado e os danos a terceiros",
    image: imageThree,
  },
  {
    id: "fx-4",
    title: "Revisão Express",
    description:
      "Rapidez e agilidade em todo o processo de revisão e manutenção do carro",
    image: imageFour,
  },
  {
    id: "fx-5",
    title: "Orçamento Fácil",
    description:
      "você solicita e um analista entra em contato para entender sua demanda e responder com agilidade",
    image: imageFive,
  },
  {
    id: "fx-6",
    title: "Jornada Descomplicada",
    description:
      "desde a entrega do veículo até a devolução, tudo é feito de forma simples, rápida e eficiente",
    image: imageSix,
  },
];
const PlayerFixed = () => {
  const visible = useContext(MenuContext);

  return (
    <InView
      trackVisibility
      delay={100}
      threshold={0.2}
      onChange={(e) => {
        if (e) {
          visible?.setMenuVisible("diferenciais");
        }
      }}
    >
      <PlayerSection>
        <span id={"diferenciais"} />
        <SliderFixed data={data} />
      </PlayerSection>
    </InView>
  );
};
export default PlayerFixed;
