import styled from "styled-components";
import { styles } from "const/styles";

export const SliderFixedContainer = styled.div`
  display: flex;
  height: 724px;
  > div {
    width: 50vw;
    box-sizing: border-box;
    :first-child {
      background-color: ${styles?.color?.primaryColor};
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      ::after {
        content: "";
        width: 60px;
        height: 60px;
        background-color: ${styles?.color?.primaryColor};
        right: calc(0% - 30px);
        top: 118px;
        transform: rotate(45deg);
        position: absolute;
      }
      ::before {
        content: "";
        width: 50vw;
        height: 25vw;
        position: absolute;
        top: -10.5vw;
        left: -18vw;
        background-color: ${styles?.color?.primaryColor};
        transform: rotate(47deg);
      }
    }
  }
  @media (min-width: 1600px) {
    > div {
      :first-child {
        ::before {
          top: -22vw;
          left: -645px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    height: auto;
    > div {
      width: 100vw;
      :first-child {
        ::after,
        ::before {
          width: 0;
          height: 0;
        }
      }
      :last-child {
        display: none;
      }
    }
  }
`;
export const ContainerSliderSection = styled.div`
  height: 100%;
  position: relative;
  width: 584px;
  margin-right: 40px;
  margin-left: 40px;
  overflow: hidden;
  > div {
    :last-child {
      display: none;
    }
  }
  @media (max-width: 991px) {
    > div {
      :last-child {
        display: block;
      }
    }
    width: 100%;
    margin: 0;
  }
`;

export const TitleSection = styled.div`
  width: 409px;
  padding-top: 119px;
  margin-bottom: 117px;
  h2,
  h5 {
    margin: 0;
  }
  h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 65px;
    line-height: 57px;
    color: ${styles?.color?.secondaryColor};
  }
  h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    color: ${styles?.color?.light};
  }
  @media (max-width: 991px) {
    padding-top: 56px;
    width: 256px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    h5 {
      font-size: 20px;
      line-height: 28px;
    }
    h2 {
      font-size: 40px;
      line-height: 40px;
    }
  }
`;

export const SlideText = styled.div`
  width: 232px;
  display: ${(props) => (props?.active ? "block" : "none")};
  h5 {
    margin: 0;
    margin-bottom: 34px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    color: ${styles?.color?.light};
  }
  p {
    margin: 0;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: ${styles?.color?.light};
  }
  @media (max-width: 991px) {
    width: 272px;
    height: 108px;
    margin-bottom: 28px;
    margin-left: auto;
    margin-right: auto;
    h5 {
      font-size: 20px;
      line-height: 22px;
      margin-bottom: 7px;
    }
    p {
      font-size: 16px;
      line-height: 19px;
    }
  }
`;

export const ContainerArrow = styled.div`
  position: absolute;
  bottom: 169px;
  right: 10px;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const SliderElements = styled.div`
  display: flex;
  margin-bottom: 39px;
  position: absolute;
  bottom: 0;
  overflow: hidden;

  @keyframes moveFixedPlayerX {
    0% {
      transform: translateX(
        ${(props) =>
          (props?.translateX === 0
            ? 0
            : props?.translateX - props?.translateX) + "px"}
      );
    }
    100% {
      transform: translateX(${(props) => props?.translateX + "px"});
    }
  }
  > div {
    display: flex;
    bottom: 0;
    transform: translateX(${(props) => props?.translateX + "px"});
    transition: all 0.45s ease-in-out;
    animation: moveFixedPlayerX 0.45s ease-in-out 0s alternate;
  }
  @media (min-width: 1350px) {
    height: 100px;
    width: 100%;
  }
  @media (max-width: 1349px) {
    width: 519px;
    height: 90px;
  }
  @media (max-width: 1199px) {
    width: 402px;
  }
  @media (max-width: 991px) {
    /* display: none; */
    position: relative;
    overflow: hidden;
    width: auto;
    height: auto;
    margin-bottom: 0;
  }
`;

export const Element = styled.div`
  width: 128px;
  height: 88px;
  cursor: pointer;
  position: relative;
  margin-right: 17px;
  background: url(${(props) => props.bg});
  background-size: cover;
  :hover {
    &::after {
      content: "";
      /* width: 100%; */
      height: 3px;
      animation: growX 0.45s ease-in-out 0s alternate forwards;
      background-color: ${styles?.color?.secondaryColor};
      bottom: -13px;
      position: absolute;
    }
  }
  ::after {
    content: "";
    width: ${(props) => (props?.active ? "100%" : "0px")};
    height: 3px;
    background-color: ${styles?.color?.secondaryColor};
    bottom: -13px;
    position: absolute;
  }

  @keyframes growX {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  :last-child {
    margin: 0;
  }
  @media (min-width: 1350px) {
    width: 129px;
  }
  @media (max-width: 1349px) {
    width: 117px;
    height: 77px;
  }
  @media (max-width: 991px) {
    display: ${(props) => (props?.active ? "block" : "none")};
    animation: ${(props) =>
      props?.active
        ? "fixedIn 0.55s ease-in-out 0s alternate forwards"
        : "fixedOut 0.55s ease-in-out 0s alternate forwards"};
    @keyframes fixedOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes fixedIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    width: 100vw;
    height: 68vw;
    margin-right: 0;
  }
`;

export const ImageSlider = styled.img`
  width: 100%;
  display: ${(props) => (props?.active ? "block" : "none")};
  animation: ${(props) =>
    props?.active
      ? "fixedInImg 0.55s ease-in-out 0s alternate forwards"
      : "fixedOutImg 0.55s ease-in-out 0s alternate forwards"};
  @keyframes fixedOutImg {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fixedInImg {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  height: 100%;
  object-fit: cover;
`;

export const PointsContainer = styled.div`
  width: 100%;
  height: 24px;
  z-index: 2;
  display: none;
  /* position: absolute; */
  align-items: center;
  justify-content: center;
  /* bottom: calc(50% - 12px); */
  > div {
    display: flex;
    justify-content: center;
    height: 100%;
    .divPoint {
      display: inline-block;
      width: 15px;
      height: 15px;
      box-sizing: border-box;
      padding: 0;
      border: 1px solid ${styles?.color?.primaryColor};
      border-radius: 50%;
      margin-right: 10px;
      margin-top: auto;
      margin-bottom: auto;
      background: transparent;
      position: relative;
      cursor: pointer;
      &.active {
        ::after {
          content: "";
          width: 9px;
          height: 9px;
          position: absolute;
          border-radius: 50%;
          top: calc(50% - 4.5px);
          left: calc(50% - 4.5px);
          background: ${styles?.color?.secondaryColor};
        }
      }
      :last-child {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 991px) {
    display: block;
    padding-top: 15px;
    background-color: white;
    > div {
      background-color: white;
      margin: 0 auto;
    }
  }
`;
