import {
  ContainerArrow,
  ImageSlider,
  PointsContainer,
  TopContainer,
} from "./styles/topo.styles";
import { useCallback, useEffect, useState } from "react";

import { ArrowSlider } from "components";
import { InView } from "react-intersection-observer";
import { useWindowDimensions } from "hooks/dimensions";

const Top = ({ data }) => {
  const { /*height,*/ width } = useWindowDimensions();
  const [element, setElement] = useState(1);
  const [firstTouch, setFirstTouch] = useState(0);
  const [firstTouchY, setFirstTouchY] = useState(0);
  const [lastTouch, setLastTouch] = useState(0);
  const [lastTouchY, setLastTouchY] = useState(0);
  const [areaVisible, setAreaVisible] = useState(false);
  const [changing, setChanging] = useState(false);
  const [timeSlider, setTimeSlider] = useState(8500);
  const [directionT, setDirectionT] = useState(true);

  const left = useCallback(() => {
    setElement((prevState) => {
      if (prevState - 1 < 1) {
        return data?.length;
      }
      return prevState - 1;
    });
  }, [data?.length]);

  const right = useCallback(() => {
    setElement((prevState) => {
      if (prevState + 1 > data?.length) {
        return 1;
      }
      return prevState + 1;
    });
  }, [data?.length]);

  const ChangingEffect = useCallback(
    (numberElement, direction = "") => {
      let intervalChanging = "";
      setChanging(true);
      setTimeSlider((prevState) => {
        if (prevState >= 8500) {
          return prevState - 1;
        }
        return prevState + 1;
      });
      intervalChanging = setTimeout(() => {
        setChanging(false);
        if (!direction) {
          setElement(numberElement);
        }
        if (direction === "left") {
          left();
        }
        if (direction === "right") {
          right();
        }
      }, 0);
      return () => clearInterval(intervalChanging);
    },
    [left, right]
  );

  useEffect(() => {
    let interval = "";

    if (areaVisible) {
      interval = setInterval(() => {
        setDirectionT(false);
        ChangingEffect(0, "right");
      }, timeSlider);

      return () => clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [areaVisible, timeSlider, ChangingEffect]);

  const getDifference = (initial, final) => {
    if (final < 0) {
      return Math.abs(final) + initial;
    }
    return Math.abs(final - initial);
  };

  const TouchEnd = () => {
    let differenceX = getDifference(firstTouch, lastTouch);
    let differenceY = getDifference(firstTouchY, lastTouchY);

    if (differenceX > differenceY) {
      if ((firstTouch > lastTouch) & (lastTouch !== 0)) {
        setDirectionT(true);
        ChangingEffect(0, "right");
      }
      if (lastTouch > firstTouch) {
        setDirectionT(false);
        ChangingEffect(0, "left");
      }
    }

    setLastTouch(0);
    setLastTouchY(0);
    setFirstTouch(0);
    setFirstTouchY(0);
  };

  return (
    <TopContainer
      minWidth={width >= 1500 ? "750px" : width <= 1400 ? "600px" : "700px"}
    >
      <InView
        className="viewDetected"
        trackVisibility
        delay={100}
        threshold={0.2}
        onChange={(e) => {
          setAreaVisible(e);
        }}
        onTouchStart={(e) => {
          if (width <= 991) {
            setFirstTouch(e?.nativeEvent?.touches[0]?.clientX);
            setFirstTouchY(e?.nativeEvent?.touches[0]?.clientY);
          }
        }}
        onTouchMove={(e) => {
          if (width <= 991) {
            setLastTouch(e?.nativeEvent?.touches[0]?.clientX);
            setLastTouchY(e?.nativeEvent?.touches[0]?.clientY);
          }
        }}
        onTouchEnd={(e) => {
          if (width <= 991) {
            TouchEnd();
          }
        }}
      >
        {data?.map((item, index) => (
          <ImageSlider
            directionT={directionT}
            changing={changing}
            active={index + 1 === element}
            key={item?.id}
            src={width <= 650 ? item?.mobile_img : item?.desktop_img}
            alt={"principalSlider" + index}
          />
        ))}{" "}
        <ContainerArrow>
          <PointsContainer>
            <div>
              {data?.map((_e, index) => (
                <div
                  key={index}
                  onClick={() => {
                    ChangingEffect(index + 1);
                  }}
                  className={`divPoint ${
                    element === index + 1 ? "active" : ""
                  }`}
                />
              ))}
            </div>
          </PointsContainer>
          <ArrowSlider
            actionLeft={() => {
              setDirectionT(false);
              ChangingEffect(0, "left");
            }}
            actionRight={() => {
              setDirectionT(true);
              ChangingEffect(0, "right");
            }}
          />
        </ContainerArrow>
      </InView>
    </TopContainer>
  );
};

export default Top;
