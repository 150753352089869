import {
  TitleMotivosContainer,
  ContainerTitle,
  WhatsappSection,
} from "./styles/titleMotivos.styles";
import { Container } from "components";
import { ButtonCustom } from "components/form";
// import { WhatsappContainer } from "./styles/whatsappSection.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const whatsappIcon = <FontAwesomeIcon icon={faWhatsapp} />;

const TitleMotivos = () => {
  return (
    <TitleMotivosContainer>
      <Container>
        <ContainerTitle>
          <p>Todos os motivos para você</p>
          <h2>terceirizar sua frota com a Momento</h2>
        </ContainerTitle>

        <WhatsappSection
          href="https://wa.me/556421422230"
          target={"_blank"}
          rel="noreferrer"
        >
          <ButtonCustom
            bgDarken={"white"}
            colorDarken={"#2f702e"}
            bg="#3CC839"
            color="white"
            wd="235px"
          >
            {whatsappIcon} fale pelo whatsapp
          </ButtonCustom>
        </WhatsappSection>
      </Container>
    </TitleMotivosContainer>
  );
};

export default TitleMotivos;
