import { useContext } from "react";
import { MenuContext } from "context";
import { InView } from "react-intersection-observer";

import { HeaderFacilitarVida, BodyFacilitarVida } from "components";
import { VidaContainer } from "./styles";
const FacilitarVida = () => {
  const visible = useContext(MenuContext);

  return (
    <InView
      trackVisibility
      delay={100}
      style={{ position: "relative" }}
      threshold={0.2}
      onChange={(e) => {
        if (e) {
          visible?.setMenuVisible("sobreNós");
        }
      }}
    >
      <VidaContainer>
        <span id={"sobreNós"} />
        <HeaderFacilitarVida />
        <BodyFacilitarVida />
      </VidaContainer>
    </InView>
  );
};
export default FacilitarVida;
