import styled from "styled-components";
import { styles } from "const/styles";

export const ContactFormContainer = styled.div`
  height: 1300px;
  padding-top: 126px;
  padding-bottom: 330px;
  box-sizing: border-box;
  /* background: red; */
  > div {
    width: calc(100% - 80px);
    box-sizing: border-box;
    margin: 0 auto;
  }
  @media (max-width: 991px) {
    height: 1500px;
    padding-top: 20px;
  }
`;

export const InformationContainer = styled.div`
  display: flex;
  height: 100%;
  background: white;
  > div {
    width: 50%;
    :first-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 736px;
    }
  }
  @media (max-width: 1349px) {
    > div {
      :first-child {
        height: 809px;
      }
    }
  }
  @media (max-width: 1061px) {
    > div {
      :first-child {
        height: 845px;
      }
    }
  }
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    > div {
      width: auto;
      :first-child {
        height: auto;
      }
    }
  }
`;
export const TextContainer = styled.div`
  h2,
  h5 {
    font-family: Inter;
    font-style: normal;
    margin: 0;
  }
  h2 {
    font-weight: 700;
    font-size: 65px;
    line-height: 57px;
    color: ${styles?.color?.primaryColor};
    margin-bottom: 11px;
  }
  h5 {
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    color: #51575c;
  }
  @media (max-width: 991px) {
    h2 {
      font-size: 40px;
      line-height: 40px;
    }
    h5 {
      color: #000000;
      font-size: 20px;
      line-height: 28px;
      width: 175px;
      margin-bottom: 48px;
    }
  }
`;
