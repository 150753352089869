import CarFooter from "assets/img/footer/momento-carFooter.png";
import Logo from "assets/img/footer/momento-logo-footer.png";
import Slogan from "assets/img/footer/momento-slogan.png";
import {
  InformationFooterContainer,
  CarImage,
  BodyInformation,
  InformationBox,
} from "./styles/information.styles";
import { Container } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedinIn,
  faInstagram,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
const linkedinIcon = <FontAwesomeIcon icon={faLinkedinIn} />;
const instagramIcon = <FontAwesomeIcon icon={faInstagram} />;
const facebookIcon = <FontAwesomeIcon icon={faFacebookF} />;

const InformationFooter = () => {
  return (
    <InformationFooterContainer>
      <div>
        <Container>hola</Container>
      </div>
      <div>
        <Container>
          <CarImage alt="card footer" src={CarFooter} />
          <BodyInformation>
            <InformationBox>
              <p>
                Avenida Juscelino Kubitschek Quadra 55 Lote 08 Setor Morada do
                Sol – Rio Verde-Goiás <br />
                CEP: 75909-080
                <span className="tel">Tel.(64) 2142-2230</span>
                <span className="email">contato@momentofrotas.com.br</span>
              </p>
              <div>
                <a
                  href="https://www.instagram.com/momentoalugueldecarros/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {instagramIcon}
                </a>
                <a
                  href="https://www.facebook.com/momentoalugueldecarros/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {facebookIcon}
                </a>
                <a
                  href="https://www.linkedin.com/company/momentolocacaodecarros/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {linkedinIcon}
                </a>
              </div>
            </InformationBox>
            <img alt="Slogan momento" src={Slogan} />
          </BodyInformation>

          <img className="logoFooter" alt="logo momento" src={Logo} />
        </Container>
      </div>
    </InformationFooterContainer>
  );
};

export default InformationFooter;
